<template>
  <div class="addWrap">
    <div class="titleWrap">
      <h2>新建笔记</h2>
      <div>
        <el-button type="info" round @click="jumpNote">取消</el-button>
        <el-button type="primary" round @click="submit">提交</el-button>
      </div>
    </div>
    <div class="mt20">
      <VueUeditorWrap ref="VueUeditorWrap" @change="change"></VueUeditorWrap>
    </div>
  </div>
</template>

<script>
import { noteAdd } from "../../assets/api";
import VueUeditorWrap from "@/views/components/ueditor/ueditor.vue";
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      content:'',
      uuid0:[],
      uuid1:[],
    };
  },
  mounted() {},
  methods: {
    jumpNote() {
      this.$router.push({
        path: "/note",
        meta: {
          type: 2,
          name: "读书笔记",
        },
      });
    },
    change(data){
      this.content=data
    },
    submit() {
      if (this.content == "") {
        this.$message.error("笔记内容不能为空");
      } else {
        const params = {
          content: this.content,
          uuid0:this.uuid0!=null?this.uuid0.toString():'',
          uuid1:this.uuid1!=null?this.uuid1.toString():'',
        };
        noteAdd(params).then((res) => {
          if (res.code == 0) {
            this.$router.push({
              path: "/note",
              meta: {
                type: 2,
                name: "读书笔记",
              },
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.addWrap {
  padding: 30px;
  box-sizing: border-box;
  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-button.is-round {
    padding: 10px 24px;
  }
  .el-button--info {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    color: #666;
    &:hover,
    &:focus {
      background: #ddd;
    }
  }
}
</style>
